import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Tooltip } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import FeedsTable from "../feed_table.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { fetchObstractProfile, loadObstractProfileFeedCount, Profile } from "../../../services/obstract.ts";
import DeleteProfile from "./delete-profile.tsx";
import { URLS } from "../../../services/urls.ts";

const ProfilePage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // `id` corresponds to `:id` in the route
  const [profile, setProfile] = useState<Profile>();
  const [canDeleteProfile, setCanDeleteProfile] = useState(false)
  const navigate = useNavigate();

  const loadProfile = async () => {
    if (!id) return;
    const res = await fetchObstractProfile(id);
    setProfile(res.data);
  };
  const loadProfileFeedCount = async(id: string) => {
    const res = await loadObstractProfileFeedCount(id)
    setCanDeleteProfile(res.data.feed_count === 0)
  }

  useEffect(() => {
    if(!id) return
    loadProfile();
    loadProfileFeedCount(id)
  }, [id]);

  useEffect(() => {
    document.title = `${profile?.name} | Obstracts Web`;
  }, [profile]);

  const handleDelete = (id: string) => {
    navigate(URLS.staffObstractProfiles());
  };

  return (
    <Box p={4}>
      <Box sx={{display: 'flex'}}>
        <Typography id="modal-title" variant="h4">
          Profile Details
        </Typography>
        {profile && (canDeleteProfile ? (<DeleteProfile profile={profile} 
            onDelete={handleDelete}
          ></DeleteProfile>): (<Tooltip title='Profile cannot be deleted because it has been used with indexed posts' >
            <div><Button sx={{marginLeft: '3rem'}} variant="contained" disabled={true}>Delete</Button></div>
          </Tooltip>
        )
        )}
      </Box>
      {profile ? (
        <Box mt={4}>
          <Typography variant="h5">Profile Setup</Typography>
          <SyntaxHighlighter language="json" style={solarizedlight}>
            {JSON.stringify(profile, null, 2)}
          </SyntaxHighlighter>
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )}
      <Typography variant="h5">Feeds belonging to the profile</Typography>
      {profile && <FeedsTable profileId={profile.id}></FeedsTable>}
    </Box>
  );
};

export default ProfilePage;
