import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getDateString } from '../../../services/utils.ts';
import { loadLatestFeedPosts } from '../../../services/obstract.ts';
import LoadingButton from '../../../components/loading_button/index.tsx';
import { useAlert } from '../../../contexts/alert-context.tsx';

const ConfirmCheckForUpdateDialog = ({
  feedId,
  lastestPostTime,
  nextPollingSchedule,
  profileName,
  profileId,
  onComplete
}) => {
  const [open, setOpen] = useState(false);
  const [isCheckingForUpdates, setIsCheckingForUpdates] = useState(false)
  const alert = useAlert()


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkForUpdates = async () => {
    setIsCheckingForUpdates(true)
    try{
        const res = await loadLatestFeedPosts(feedId)
        onComplete(res.data.active_job_id)
        setOpen(false)
    } catch(err) {
        setIsCheckingForUpdates(false)
        if (err?.response?.status === 400) {
            return alert.showAlert(err?.response?.data?.details?.detail, 'error')
        }
        throw err
    }
    setIsCheckingForUpdates(false)
  }

  const handleContinue = async () => {
    await checkForUpdates()
  };

  return (
    <>
      <Button sx={{marginLeft: '3rem'}} variant="contained" color="primary" onClick={handleClickOpen}>
        Check for updates
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Check For Updates</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The next cron job for this feed will run at <strong>{getDateString(nextPollingSchedule)}</strong>. You can update this feed before this cron to retrieve any new posts since the last indexed post time, which is <strong>{getDateString(lastestPostTime)}</strong>.
          </DialogContentText>
          <DialogContentText>
            The currently active profile for this blog <strong>{profileName} ({profileId})</strong> will be used.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='contained' color='error'>
            Cancel
          </Button>
          <LoadingButton isLoading={isCheckingForUpdates} onClick={handleContinue} variant='contained' autoFocus>
            Continue
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmCheckForUpdateDialog;