import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Chip,
    Tooltip,
    Select,
    MenuItem,
} from '@mui/material';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { createNewPost, Feed, fetchObstractFeed, fetchObstractProfiles, Profile } from '../../../services/obstract.ts';
import { useAlert } from '../../../contexts/alert-context.tsx';
import { Link, useNavigate } from 'react-router-dom';
import { URLS } from '../../../services/urls.ts';

const REACT_APP_OBSTRACTS_WEB_URL = process.env.REACT_APP_OBSTRACTS_WEB_URL;

interface NewPostModalProps {
    open: boolean;
    onClose: () => void;
    feedId: string;
    isSkeleton: boolean;
    onPostCreated: () => void;
    profileId: string;
}

const NewPostModal: React.FC<NewPostModalProps> = ({ isSkeleton, onClose, feedId, onPostCreated, profileId }) => {
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [title, setTitle] = useState<string>('');
    const [selectedProfileId, setSelectedProfileId] = useState<string>('');
    const [link, setLink] = useState<string>('');
    const [pubdate, setPubdate] = useState<Date | null>(null);
    const [author, setAuthor] = useState<string>('');
    const [categories, setCategories] = useState<string[]>([]);
    const [newCategory, setNewCategory] = useState<string>('');
    const [feed, setFeed] = useState<Feed>()
    const [selectedProfile, setSelectedProfile] = useState<Profile>()
    const [errors, setErrors] = useState<{
        title?: string[],
        link?: string[],
        pubdate?: string[],
        author?: string[],
        profile_id?: string[],
    }>({})
    const alert = useAlert()
    const navigate = useNavigate()

    const handleAddCategory = () => {
        if (newCategory && !categories.includes(newCategory)) {
            setCategories([...categories, newCategory]);
            setNewCategory('');
        }
    };

    const loadProfiles = async (pageNumber: number) => {
        const res = await fetchObstractProfiles(pageNumber);
        setProfiles(res.data.profiles);
        setSelectedProfileId(res.data.profiles[0]?.id)
    };

    useEffect(() => {
      // if(isSkeleton) return
      console.log(7889)
      profiles.find(profile => {
        console.log(profile.id, feed?.profile_id, profile.id === feed?.profile_id)
        return false
      })
      setSelectedProfile(profiles.find(profile => profile.id === feed?.profile_id))
    }, [feed, profiles])

    useEffect(() => {
      console.log({selectedProfile})
    }, [selectedProfile])


    const loadFeed = async () => {
      if(!feedId) return
        const res = await fetchObstractFeed(feedId || '');
        setFeed(res.data);
    }

    useEffect(() => {
      loadFeed()
    }, [feedId])
    
    useEffect(() => {
        loadProfiles(1)
    }, [])

    useEffect(() => {
        setSelectedProfileId(profileId)
    }, [profileId])

    const handleRemoveCategory = (category: string) => {
        setCategories(categories.filter(c => c !== category));
    };

    const handleSubmit = async () => {
        if (title && link && pubdate) {
            try {
                await createNewPost(feedId, selectedProfileId, { title, link, pubdate: pubdate, author, categories, });
                onPostCreated(); // Refresh feed data
                onClose(); // Close modal
            } catch (err) {
                if (err.response && err.response.status === 400) {
                    setErrors(err.response.data.details.posts[0])
                }
            }
        } else {
            setErrors({
                title: title ? [] : ['This field cannot be empty'],
                link: link ? [] : ['This field cannot be empty'],
                pubdate: pubdate ? [] : ['This field cannot be empty'],
            })
        }
    };

    return (
        <Box>
            <Typography variant="h4">
                Create New Post
            </Typography>
            <Typography>Add a post manually. The profile used will be the same as the profile originally specified when adding the blog (so will be the same as all other posts currently indexed for this blog). Obstracts will use the URL (link) specified to grab the content for the blog</Typography>
            <Box mt={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Feed URL</strong>
                    <Tooltip title="[FIXED] This is the Feed URL the post will be added to. Shown here for reference."><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <TextField
                    label="Feed URL"
                    fullWidth
                    margin="normal"
                    disabled
                    value={`${REACT_APP_OBSTRACTS_WEB_URL}${URLS.staffObstractFeed(feedId)} (${feedId})`}
                />
            </Box>


            <Box mt={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Feed ID</strong>
                    <Tooltip title="[FIXED] This is the Feed ID the post will be added to. Shown here for reference."><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <TextField
                    label="Feed ID"
                    fullWidth
                    margin="normal"
                    disabled
                    value={feedId}
                />
            </Box>


            <Box mt={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Feed Title</strong>
                    <Tooltip title="[FIXED] This is the Feed Title the post will be added to. Shown here for reference."><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <TextField
                    label="Feed Title"
                    fullWidth
                    margin="normal"
                    disabled
                    value={feed?.obstract_feed_metadata?.title || 'a'}
                />
            </Box>

            {!isSkeleton && <Box marginY={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Profile </strong>
                    <Tooltip title='[FIXED] The currently active Profile for this blog. Shown here for reference.'>
                        <HelpCenterIcon>This can't be changed</HelpCenterIcon>
                    </Tooltip>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <TextField
                      label="Profile"
                      fullWidth
                      margin="normal"
                      disabled
                      value={`${selectedProfile?.name || ''} (${selectedProfile?.id || ''})`}
                  />
                  <Link style={{flex: 'auto'}} to={URLS.staffObstractProfileDetail(selectedProfileId)}>
                    <Button variant='contained' sx={{flex: 'auto'}}>View Profile</Button>
                  </Link>
                </Box>
                {errors?.profile_id?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            </Box>}

            <Box mt={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Post Title</strong>
                    <Tooltip title='[REQUIRED] What will be shown to users as the title of this post.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <TextField
                    label="Title"
                    fullWidth
                    margin="normal"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                {errors?.title?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            </Box>

            {isSkeleton && <Box marginY={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Extraction Profile</strong>
                    <Tooltip title='[REQUIRED] The extraction profile that will be used for data extraction from this post. Is only visible to staff users. Create one under extraction profiles.'>
                        <HelpCenterIcon></HelpCenterIcon>
                    </Tooltip>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <Select
                      fullWidth
                      name="extractions"
                      value={profileId}
                      onChange={(e) => setSelectedProfileId(e.target.value)}
                  >
                      {profiles.map((profile) => (
                          <MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>
                      ))}
                  </Select>
                  <Link style={{flex: 'auto'}} to={URLS.staffObstractProfiles()}>
                    <Button variant='contained' sx={{flex: 'auto'}}>View Profiles</Button>
                  </Link>
                </Box>
                {errors?.profile_id?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            </Box>}
            <Box mt={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Post URL (link)</strong>
                    <Tooltip title='[REQUIRED] The URL of the blog post. This is where the content of the post is found to be indexed. It cannot be the same as the url of a post already in this feed.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <TextField
                    label="Link"
                    fullWidth
                    margin="normal"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                />
                {errors?.link?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            </Box>


            <Box mt={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Publication Date</strong>
                    <Tooltip title='[REQUIRED] The date of the blog post in the format YYYY-MM-DD.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <TextField
                    type="datetime-local"
                    fullWidth
                    margin="normal"
                    value={pubdate ? pubdate.toISOString().substring(0, 16) : ''}
                    onChange={(e) => setPubdate(e.target.value ? new Date(e.target.value) : null)}
                />
                {errors?.pubdate?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            </Box>

            <Box mt={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Author</strong>
                    <Tooltip title='[OPTIONAL] The name of the author of this post.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <TextField
                    label="Author"
                    fullWidth
                    margin="normal"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                />
                {errors?.author?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            </Box>

            <Box mt={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Categories</strong>
                    <Tooltip title='[OPTIONAL] Categories you want to classify this post with.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <Box sx={{ marginTop: '0.5rem' }}>
                    {categories.map((value) => (
                        <Chip key={value} label={value} onDelete={() => handleRemoveCategory(value)} />
                    ))}
                </Box>

                <Box sx={{ display: 'flex', marginTop: '0.5rem', alignItems: 'center' }}>
                    <TextField
                        label="Add Category"
                        value={newCategory}
                        fullWidth
                        onChange={(e) => setNewCategory(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleAddCategory()}
                    />
                    <Box>
                        <Button variant='contained' onClick={() => handleAddCategory()}>Add Category</Button>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ marginTop: '2rem' }}>
                <Button color='error' variant='contained' onClick={() => navigate(-1)}>Cancel</Button>
                <Button sx={{ marginLeft: '2rem' }} variant="contained" color="primary" onClick={handleSubmit}>
                    Create Post
                </Button>
            </Box>
        </Box >
    );
};

export default NewPostModal;