import React, { useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { deleteObstractProfile } from "../../../services/obstract.ts";
import LoadingButton from '../../../components/loading_button/index.tsx';

type Profile = {
  id: string;
  created: string;
  name: string;
  extractions: string[];
  whitelists: string[];
  aliases: string[];
  relationship_mode: string;
  extract_text_from_image: boolean;
};

interface DeleteProfileProps {
  profile: Profile;
  onDelete: (id: string) => void; // Function to handle delete
}

const DeleteProfile: React.FC<DeleteProfileProps> = ({ profile, onDelete }) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false)

  const handleDeleteClick = () => {
    setOpenConfirmModal(true);
  };

  const handleConfirmDelete = async () => {
    setLoading(true)
    try {
      await deleteObstractProfile(profile.id)
      setOpenConfirmModal(false);
      onDelete(profile.id)
    } catch (error) {}
    finally {
      setLoading(false)
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmModal(false); // Close confirmation modal
  };

  return (
    <>
      <Button
        variant="contained"
        color="error"
        onClick={handleDeleteClick}
        style={{ marginLeft: "3rem" }}
      >
        Delete
      </Button>

      {/* Confirmation Modal for Deletion */}
      <Dialog
        open={openConfirmModal}
        onClose={handleCancelDelete}
        aria-labelledby="confirm-delete-title"
      >
        <DialogTitle>
          <Typography id="confirm-delete-title" variant="h6" component="h2">
            Confirm Deletion
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" style={{ marginTop: "16px" }}>
            Are you sure you want to delete this profile: {profile.name}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCancelDelete}
            style={{ marginLeft: "8px" }}
          >
            Cancel
          </Button>
          <LoadingButton
            isLoading={loading}
            variant="contained"
            color="error"
            onClick={handleConfirmDelete}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteProfile;
