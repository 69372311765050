import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Box,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  Typography,
  Tooltip,
} from '@mui/material';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { createObstractFeed, Feed, fetchObstractProfiles, Profile, updateObstractFeed } from '../../../services/obstract.ts';
import LoadingButton from '../../../components/loading_button/index.tsx';

interface FeedFormDialogProps {
  open: boolean;
  feed?: Feed,
  isEdit?: boolean;
  onClose: () => void;
  onComplete: () => void;
}

const FeedForm: React.FC<FeedFormDialogProps> = ({
  open,
  feed,
  isEdit,
  onClose,
  onComplete,
}) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    profile_id: '',
    url: '',
    include_remote_blogs: false,
    is_public: false,
    polling_schedule_minute: 720,
    title: '',
    description: '',
    pretty_url: '',
    use_search_index: false,
  });
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [errors, setErrors] = useState<{
    url?: string[],
    profile_id?: string[],
    title?: string[],
    description?: string[],
    pretty_url?: string[],
    non_field_errors?: string[],
  }>({
    url: [],
    profile_id: [],
    title: [],
    description: [],
    pretty_url: [],
  })

  const loadProfiles = async (pageNumber: number) => {
    const res = await fetchObstractProfiles(pageNumber);
    setProfiles(res.data.profiles);
  };

  useEffect(() => {
    loadProfiles(1)
  }, [])

  useEffect(() => {
    // Reset form for adding a new entry
    setFormData({
      profile_id: feed?.profile_id ?? '',
      url: feed?.obstract_feed_metadata.url ?? '',
      include_remote_blogs: feed?.obstract_feed_metadata.include_remote_blogs || false,
      is_public: feed?.is_public ?? false,
      polling_schedule_minute: feed?.polling_schedule_minute ?? 720,
      title: feed?.obstract_feed_metadata.title ?? '',
      description: feed?.obstract_feed_metadata.description ?? '',
      pretty_url: feed?.obstract_feed_metadata.pretty_url ?? '',
      use_search_index: feed?.obstract_feed_metadata.use_search_index || false,
    });
    setErrors({})
  }, [open, feed]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleProfileChange = (value: string) => {
    setFormData((prev) => ({
      ...prev,
      profile_id: value
    }));
  };

  const isValidURL = (url) => {
    if (!url) return true
    const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
    return regex.test(url);
  }

  const validateData = (data) => {
    let isValid = true
    const errors = {
      url: [''],
      pretty_url: [''],
    }
    if (!isValidURL(formData.url)) {
      errors["url"] = ["Invalid URL"]
      isValid = false
    }
    if (!isValidURL(formData.pretty_url)) {
      errors["pretty_url"] = ["Invalid URL"]
      isValid = false
    }
    setErrors(errors)
    return isValid
  }
  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateData(formData)) return
    setLoading(true)
    try {
      await updateObstractFeed(feed?.id || '', formData);
      onComplete();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setErrors(err.response.data.details || err.response.data)
      } else {
        throw err
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateData(formData)) return
    setLoading(true)
    try {
      await createObstractFeed(formData); // Add new entry
      setFormData({
        profile_id: '',
        url: '',
        include_remote_blogs: false,
        is_public: false,
        polling_schedule_minute: 720,
        title: '',
        description: '',
        pretty_url: '',
        use_search_index: false,
      });
      onComplete();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setErrors(err.response.data.details || err.response.data)
      } else {
        throw err
      }
    } finally {
      setLoading(false)
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {isEdit ? 'Edit a Feed' : 'Add a New Feed'}
      </Typography>
      <Typography>
        {isEdit ?
          <Box marginY={2}>
            <strong>Feed ID</strong><span></span>
            <TextField
              margin="dense"
              name="id"
              label="ID"
              type="text"
              fullWidth
              value={feed?.id}
              onChange={handleChange}
              disabled
            />
            {errors?.url?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
          </Box>
          :
          <>
            Add a new polled feed. You cannot add skeleton feeds here. Go back to the Feed list staff page to add a blog.
          </>}
      </Typography>

      {!isEdit && <Box marginY={2}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <strong>Extraction Profile</strong>
          <Tooltip title='[REQUIRED] The extraction profile that will be used for data extraction from this post. Is only visible to staff users. Create one under extraction profiles.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
        </Box>
        <Select
          name="profile"
          label="Profile"
          style={{ flex: 'auto' }}
          fullWidth
          value={formData.profile_id}
          onChange={(e) => handleProfileChange(e.target.value)}
        >
          {profiles.map((profile) => (
            <MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>
          ))}
        </Select>
        {errors?.profile_id?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
      </Box>}

      <Box marginY={2}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <strong>Feed URL</strong>
          <Tooltip title='[REQUIRED] This is the RSS/ATOM feed of the blog OR it is the URL of the blog (must set search mode to true if not using RSS/ATOM feed)'><HelpCenterIcon></HelpCenterIcon></Tooltip>
        </Box>
        <TextField
          margin="dense"
          name="url"
          label="URL"
          type="url"
          fullWidth
          value={formData.url}
          onChange={handleChange}
          required
          disabled={isEdit}
        />
        {errors?.url?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
      </Box>

      <Box marginY={2}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <strong>Title </strong>
          <Tooltip title='[OPTIONAL] The blog title will be inherited from the metadata data in the feed title. If you want to override this setting, you can enter a title here manually.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
        </Box>
        <TextField
          margin="dense"
          name="title"
          label="Title"
          type="text"
          fullWidth
          value={formData.title}
          onChange={handleChange}
        />
        {errors?.title?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
      </Box>

      <Box marginY={2}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <strong>Description </strong>
          <Tooltip title='[OPTIONAL] The blog description will be inherited from the metadata data in the feed description. If you want to override this setting, you can enter a description here manually.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
        </Box>
        <TextField
          margin="dense"
          name="description"
          label="Description"
          type="description"
          fullWidth
          value={formData.description}
          onChange={handleChange}
        />
        {errors?.description?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
      </Box>

      <Box marginY={2}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <strong>Pretty URL</strong>
          <Tooltip title='[OPTIONAL] Will be shown to users as blog url in the app. This allows users to easily find the URL of the blog, instead of only the raw feed URL.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
        </Box>
        <TextField
          margin="dense"
          name="pretty_url"
          label="Pretty URL"
          type="pretty_url"
          fullWidth
          value={formData.pretty_url}
          onChange={handleChange}
        />
        {errors?.pretty_url?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
      </Box>


      {!isEdit && <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Switch
              checked={formData.include_remote_blogs}
              onChange={handleChange}
              name="include_remote_blogs"
              color="primary"
            />
          }
          label="Include Remote Blogs"
        />
        <Tooltip title='[REQUIRED] This is a history4feed setting. If set to true, post URLs in the feed that do not have the same domain as the feed will be indexed. If false these posts will be skipped.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
      </Box>}

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Switch
              disabled={isEdit}
              checked={formData.use_search_index}
              onChange={handleChange}
              name="use_search_index"
              color="primary"
            />
          }
          label="Use search index mode"
        />
        <Tooltip title='Use search index'><HelpCenterIcon></HelpCenterIcon></Tooltip>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Switch
              checked={formData.is_public}
              onChange={handleChange}
              name="is_public"
              color="primary"
            />
          }
          label="Is Public"
        />
        <Tooltip title='[REQUIRED] If set to true, all users will be able to see and subscribe to this blog. Can be modified later.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
      </Box>

      <Box marginY={2}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <strong>Polling schedule</strong>
          <Tooltip title='[REQUIRED] This is how often the feed will be polled for updates. Updates are checked by using the last indexed posts published time and the current time to uncover if any new posts have been posted during this time.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
        </Box>
        <TextField
          margin="dense"
          name="polling_schedule_minute"
          label="Polling schedule (Minutes)"
          type="number"
          fullWidth
          value={formData.polling_schedule_minute}
          onChange={handleChange}
          required
        />
      </Box>

      {
        isEdit && <Box marginY={2}>
          <strong>Profile</strong>
          <Tooltip title='[REQUIRED] The extraction profile that will be used for data extraction from this post. Is only visible to staff users. Create one under extraction profiles.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
          <Select
            name="profile"
            label="Profile"
            style={{ flex: 'auto' }}
            fullWidth
            value={formData.profile_id}
            onChange={(e) => handleProfileChange(e.target.value)}
          >
            {profiles.map((profile) => (
              <MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>
            ))}
          </Select>
          {errors?.profile_id?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
        </Box>
      }

      {errors?.non_field_errors?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}

      <Box sx={{ marginTop: '1rem' }}>
        <Button variant='contained' color='error' onClick={onClose}>
          Cancel
        </Button>
        {isEdit ? <LoadingButton isLoading={loading} variant='contained' color='success' sx={{ marginLeft: '1rem' }} onClick={handleUpdate}>
          Submit
        </LoadingButton> :
          <LoadingButton isLoading={loading} variant='contained' color='success' sx={{ marginLeft: '1rem' }} onClick={handleSubmit}>
            Add
          </LoadingButton>
        }
      </Box>

    </Box >
  );
};

export default FeedForm;
