import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    Chip,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Switch,
    Typography,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Tooltip,
} from '@mui/material';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { useNavigate } from "react-router-dom";
import { createProfile } from '../../../services/obstract.ts';
import LoadingButton from '../../../components/loading_button/index.tsx';
import { cleanData } from '../../../services/utils.ts';
import ExtractionInput from './extractions.tsx';

const ai_settings_options = [
    "openai:gpt-4o",
    "openai:gpt-4o-mini",
    "openai:gpt-4-turbo",
    "openai:gpt-4",
]

function ObstractsProfileAdd() {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState<{
        name: string,
        extractions: string[],
        ai_settings_extractions: string[],
        ai_settings_relationships: string,
        ai_summary_provider: string,
        ai_content_check_provider: string,
        relationship_mode: string,
        extract_text_from_image: boolean,
        defang: boolean,
        ignore_image_refs: boolean,
        ignore_link_refs: boolean,
        ignore_extraction_boundary: boolean,
        ai_content_check: boolean,
        ai_create_attack_flow: boolean,
        ignore_embedded_relationships: boolean,
        ignore_embedded_relationships_sro: boolean,
        ignore_embedded_relationships_smo: boolean,
    }>({
        name: '',
        extractions: [],
        ai_settings_extractions: [],
        ai_settings_relationships: ai_settings_options[0],
        ai_summary_provider: ai_settings_options[0],
        ai_content_check_provider: ai_settings_options[0],
        relationship_mode: 'standard',
        extract_text_from_image: true,
        defang: true,
        ignore_image_refs: true,
        ignore_link_refs: true,
        ignore_extraction_boundary: true,
        ai_content_check: false,
        ai_create_attack_flow: false,
        ignore_embedded_relationships: false,
        ignore_embedded_relationships_sro: true,
        ignore_embedded_relationships_smo: true,
    });
    const [errors, setErrors] = useState<{
        name?: string[],
        extractions?: string[],
        ai_settings_extractions?: string[],
        ai_settings_relationships?: string[],
        ai_summary_provider?: string[],
        ai_content_check_provider?: string[],
        non_field_errors?: string[],
    }>({})

    const navigate = useNavigate()
    useEffect(() => {
        setFormData({
            name: '',
            extractions: [],
            relationship_mode: 'standard',
            ai_settings_relationships: '',
            ai_summary_provider: '',
            ai_content_check_provider: '',
            ai_settings_extractions: [],
            extract_text_from_image: true,
            defang: true,
            ignore_image_refs: true,
            ignore_link_refs: true,
            ignore_extraction_boundary: true,
            ai_content_check: false,
            ai_create_attack_flow: false,
            ignore_embedded_relationships: false,
            ignore_embedded_relationships_sro: true,
            ignore_embedded_relationships_smo: true,
        });
        setErrors({})

    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleArrayDelete = (arrayName: string, index: number) => {
        const newArray = [...(formData[arrayName as keyof typeof formData] as string[])];
        newArray.splice(index, 1);
        setFormData((prev) => ({ ...prev, [arrayName]: newArray }));
    };


    const addEntry = async (data: any) => {
        await createProfile(cleanData(data))
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const { ...submitData } = formData
        setLoading(true)
        try {
            await addEntry(submitData);
            navigate(-1);
        } catch (err) {
            if (err.response.status === 400) {
                setErrors(err?.response?.data?.details)
            }
        } finally {
            setLoading(false)
        }
    };

    const handleExtractionChange = (value: string | string[]) => {
        setFormData((prev) => ({
            ...prev,
            extractions: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    const handleAISettingsChange = (value: string | string[]) => {
        setFormData((prev) => ({
            ...prev,
            ai_settings_extractions: typeof value === 'string' ? value.split(',') : value,
        }));
    };
    useEffect(() => {
        document.title = 'Add a New Extraction Profile | Obstracts Web'
    }, [])



    return (
        <Box p={4}>
            <Typography variant='h4'>{'Add Profile'}</Typography>

            <Typography>Please enter the details of the new profile.</Typography>

            <Box mt={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Name</strong>
                    <Tooltip title='[REQUIRED] The name of extraction profile. Is visible to staff users only.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                {errors?.name?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            </Box>

            {/* Extractions */}
            <Box marginY={2}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Selected Extractions</strong>
                    <Tooltip title='[REQUIRED] These are the data types you want to be extracted from the posts. Is visible to staff users only.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <ExtractionInput
                    value={formData.extractions}
                    onChange={(value) => handleExtractionChange(value)}
                ></ExtractionInput>
                {errors?.extractions?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            </Box>

            <Box marginY={2}>
                <div>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <strong>AI Extractions Settings</strong>
                        <Tooltip title='[REQUIRED IF ANY AI EXTRACTIONS SELECTED] The AI model to be used for extractions.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
                    </Box>
                    {formData.ai_settings_extractions.map((option, index) => (
                        <Chip
                            key={option}
                            label={option}
                            onDelete={() => handleArrayDelete('ai_settings_extractions', index)}
                            style={{ margin: '4px' }}
                        />
                    ))}
                </div>
                <FormControl fullWidth>
                    <InputLabel>Extractions</InputLabel>
                    <Select
                        fullWidth
                        multiple
                        value={formData.ai_settings_extractions}
                        onChange={(ev) => handleAISettingsChange(ev.target.value)}
                        input={<OutlinedInput label="Extractions" />}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {ai_settings_options.map((option) => (
                            <MenuItem key={option} value={option}>
                                <Checkbox checked={formData.ai_settings_extractions.includes(option)} />
                                <ListItemText primary={option} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {errors?.extractions?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            </Box>

            <FormControl fullWidth margin="dense">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>Relationship Mode</strong>
                    <Tooltip title='[REQUIRED] How the relationships should be created between extractions in the report.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <Select
                    name="relationship_mode"
                    value={formData.relationship_mode}
                    onChange={(e) => setFormData({ ...formData, relationship_mode: e.target.value })}
                >
                    <MenuItem value="ai">AI</MenuItem>
                    <MenuItem value="standard">Standard</MenuItem>
                </Select>
            </FormControl>
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>AI Relationships Settings</strong>
                    <Tooltip title='[REQUIRED IF ANY AI EXTRACTIONS SELECTED OR ATTACK FLOW USED] The AI model to be used for relationship generation.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <Select

                    sx={{ width: '100%' }}

                    label="AI Settings Relationships"
                    name="ai_settings_relationships"
                    value={formData.ai_settings_relationships}
                    onChange={(e) => setFormData({ ...formData, ai_settings_relationships: e.target.value })}
                >
                    {ai_settings_options.map((extraction) => (
                        <MenuItem key={extraction} value={extraction}>{extraction}</MenuItem>
                    ))}
                </Select>
                {errors?.ai_settings_relationships?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            </Box>

            <Box mb={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.ai_create_attack_flow}
                            color="primary"
                            onChange={(e) => setFormData({ ...formData, ai_create_attack_flow: e.target.checked })}
                        />
                    }
                    label="Create Attack Flow"
                />
                <Tooltip title='[REQUIRED] Wether or not an Attack Flow should be created.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
            </Box>

            <Box mb={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.extract_text_from_image}
                            color="primary"
                            onChange={(e) => setFormData({ ...formData, extract_text_from_image: e.target.checked })}
                        />
                    }
                    label="Extract text from image"
                />
                <Tooltip title='[REQUIRED] If an image is detected in the post, set this to true if you want the text in the image to be converted to plain text and used for extractions.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
            </Box>


            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>AI Summary Provider</strong>
                    <Tooltip title='[OPTIONAL] This is used to generate post summaries. In production this should always be true.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <Select

                    sx={{ width: '100%' }}

                    label="AI Summary Provider"
                    name="ai_summary_provider"
                    value={formData.ai_summary_provider}
                    onChange={(e) => setFormData({ ...formData, ai_summary_provider: e.target.value })}
                >
                    {ai_settings_options.map((extraction) => (
                        <MenuItem key={extraction} value={extraction}>{extraction}</MenuItem>
                    ))}
                </Select>
                {errors?.ai_summary_provider?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            </Box>

            <Box mb={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.ai_content_check}
                            color="primary"
                            onChange={(e) => setFormData({ ...formData, ai_content_check: e.target.checked })}
                        />
                    }
                    label="AI Content Check"
                />
                <Tooltip title='[REQUIRED] If set to true, the AI will try and classify the report and decide if it is related to threat intelligence. If the AI decides it is not, the post will not be processed for extractions. Should always be true in production.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
            </Box>
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <strong>AI Content Check Provider</strong>
                    <Tooltip title='[REQUIRED - IF AI CONTENT CHECK SET TO TRUE] The AI model to do the content check.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
                </Box>
                <Select
                    sx={{ width: '100%' }}
                    label="AI Content Check Provider"
                    name="ai_content_check_provider "
                    value={formData.ai_content_check_provider}
                    onChange={(e) => setFormData({ ...formData, ai_content_check_provider: e.target.value })}
                >
                    {ai_settings_options.map((extraction) => (
                        <MenuItem key={extraction} value={extraction}>{extraction}</MenuItem>
                    ))}
                </Select>
                {errors?.ai_content_check_provider?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            </Box>
            <Box mb={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.defang}
                            color="primary"
                            onChange={(e) => setFormData({ ...formData, defang: e.target.checked })}
                        />
                    }
                    label="Defang"
                />
                <Tooltip title='[REQUIRED] If the text should be defanged before processing. On production this should always be true.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
            </Box>
            <Box mb={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.ignore_image_refs}
                            color="primary"
                            onChange={(e) => setFormData({ ...formData, ignore_image_refs: e.target.checked })}
                        />
                    }
                    label="Ignore Image Refs"
                />
                <Tooltip title='[REQUIRED] If set to true, any URLs inside image tags will be ignored during extraction. Should always be true in production.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
            </Box>
            <Box mb={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.ignore_link_refs}
                            color="primary"
                            onChange={(e) => setFormData({ ...formData, ignore_link_refs: e.target.checked })}
                        />
                    }
                    label="Ignore Link Refs"
                />
                <Tooltip title='[REQUIRED] If set to true, any URLs inside link (a href) tags will be ignored during extraction. Should always be true in production.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
            </Box>
            <Box mb={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.ignore_extraction_boundary}
                            color="primary"
                            onChange={(e) => setFormData({ ...formData, ignore_extraction_boundary: e.target.checked })}
                        />
                    }
                    label="Ignore Extraction Boundary"
                />
                <Tooltip title='[REQUIRED] Defines if a string boundary can generate multiple extractions (e.g. url, domain, etc). Setting to true will allow multiple extractions from the same string. Should be set to false in production.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
            </Box>

            <Box mb={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.ignore_embedded_relationships}
                            color="primary"
                            onChange={(e) => setFormData({ ...formData, ignore_embedded_relationships: e.target.checked })}
                        />
                    }
                    label="Ignore embedded relationships"
                />
                <Tooltip title='[REQUIRED] if true passed, this will stop ANY embedded relationships from being generated. This applies for all object types (SDO, SCO, SRO, SMO). If you want to target certain object types see ignore_embedded_relationships_sro and ignore_embedded_relationships_sro flags. This should always be set to false in production.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
            </Box>
            <Box mb={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.ignore_embedded_relationships_sro}
                            color="primary"
                            onChange={(e) => setFormData({ ...formData, ignore_embedded_relationships_sro: e.target.checked })}
                        />
                    }
                    label="Ignore embedded relationships (SRO)"
                />
                <Tooltip title='[REQUIRED] If true passed, will stop any embedded relationships from being generated from SRO objects. This should always be set to true in production.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
            </Box>
            <Box mb={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.ignore_embedded_relationships_smo}
                            color="primary"
                            onChange={(e) => setFormData({ ...formData, ignore_embedded_relationships_smo: e.target.checked })}
                        />
                    }
                    label="Ignore embedded relationships (SMO)"
                />
                <Tooltip title='[REQUIRED] If true passed, will stop any embedded relationships from being generated from SMO objects. This should always be set to true in production.'><HelpCenterIcon></HelpCenterIcon></Tooltip>
            </Box>
            {errors?.non_field_errors?.map(error => <Typography sx={{ color: 'red' }}>{error}</Typography>)}
            <Button variant='contained' sx={{ textTransform: 'uppercase' }} onClick={() => navigate(-1)} color="secondary">
                Cancel
            </Button>
            <LoadingButton isLoading={loading} variant='contained' sx={{ textTransform: 'uppercase', marginLeft: '3rem' }} onClick={handleSubmit} color="primary">
                Add
            </LoadingButton>
        </Box>
    );
}

export default ObstractsProfileAdd;
