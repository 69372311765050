import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Typography,
} from '@mui/material';
import { URLS } from '../../../services/urls.ts';
import { Link, useNavigate } from 'react-router-dom';
import { getDateString } from '../../../services/utils.ts';
import { Feed } from '../../../services/obstract.ts';

const CheckForUpdateCompleteDialog = ({ open, onClose, jobId, feed }: { feed: Feed, open: boolean, onClose: () => void, jobId: string }) => {
    const naviagete = useNavigate()

    const handleClose = () => {
        onClose();
        naviagete(URLS.staffObstractFeeds())
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Checking for updates</DialogTitle>
            <DialogContent>
                <DialogContentText>
                The server is checking for new post since the last post time {getDateString(feed.obstract_feed_metadata.latest_item_pubdate)}.
                    <Typography>You can track this request using job ID: 
                      <Link to={URLS.staffObstractJob(feed.id, jobId)}>{jobId}</Link>
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color='error' variant='contained' onClick={handleClose}>
                    Return to feed list
                </Button>
                <Link to={URLS.staffObstractJob(feed.id, jobId)}>
                    <Button variant='contained' onClick={handleClose} color="primary">
                        Go to job
                    </Button>
                </Link>
            </DialogActions>
        </Dialog>
    );
};

export default CheckForUpdateCompleteDialog;