import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Checkbox,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Table,
    Tab,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAlert } from '../../../contexts/alert-context.tsx';
import { TeamContext } from '../../../contexts/team-context.tsx';
import "./index.css"
import { loadAllExtractions } from '../../../services/obstract.ts';

interface ExtractionInputProps {
    value: string[];
    onChange: (value) => void;
}

const ExtractionInput: React.FC<ExtractionInputProps> = ({
    value, onChange
}) => {

    const [loading, setLoading] = useState(false)
    const [patternExtraction, setPatternExtractions] = useState<{ name: string; id: string, description: string }[]>([]);
    const [lookupExtraction, setLookupExtractions] = useState<{ name: string; id: string, description: string }[]>([]);
    const [aiExtraction, setAIExtractions] = useState<{ name: string; id: string, description: string }[]>([]);
    const { activeTeam } = useContext(TeamContext);
    const alert = useAlert()
    const [selectedExtractions, setSelectedExtractions] = useState({})

    const initExtractions = async () => {
        const results = await loadAllExtractions()
        setPatternExtractions(
            results.filter(item => item.id.includes('pattern_'))
        )
        setLookupExtractions(
            results.filter(item => item.id.includes('lookup_'))
        )
        setAIExtractions(
            results.filter(item => item.id.includes('ai_'))
        );
    };

    const toggleExtraction = (name: string) => {
        setSelectedExtractions((selectedExtractions) => ({ ...selectedExtractions, [name]: !selectedExtractions[name] }))
    }

    useEffect(() => {
        const selectedExtractions = {}
        value.forEach(item => {
            selectedExtractions[item] = true
        })
        setSelectedExtractions(selectedExtractions)
    }, [])

    useEffect(() => {
        const data = Object.keys(selectedExtractions).filter(extraction => selectedExtractions[extraction])
        onChange(data)
    }, [selectedExtractions])

    useEffect(() => {
        initExtractions()
    }, []);

    return (
        <Box marginY={2}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Box sx={{ alignItems: 'center' }}>
                        <Typography component="span">Pattern Extractions</Typography>
                        <span style={{ fontSize: '0.8rem' }}> (Use regular expressions to extract data)</span>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Table>
                        <TableHead>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                        </TableHead>
                        <TableBody>
                            {patternExtraction.map((extraction) => (
                                <TableRow key={extraction.id} sx={{ cursor: 'pointer' }}>
                                    <TableCell>
                                        <Checkbox onChange={() => toggleExtraction(extraction.id)} checked={selectedExtractions[extraction.id]} />
                                    </TableCell>
                                    <TableCell>{extraction.name}</TableCell>
                                    <TableCell>{extraction.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Box sx={{ alignItems: 'center' }}>
                        <Typography component="span">Lookup Extractions </Typography>
                        <span style={{ fontSize: '0.8rem' }}> (Use lookup tables to extract data)</span>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Table>
                        <TableHead>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                        </TableHead>
                        <TableBody>
                            {lookupExtraction.map((extraction) => (
                                <TableRow key={extraction.id} sx={{ cursor: 'pointer' }}>
                                    <TableCell>
                                        <Checkbox onChange={() => toggleExtraction(extraction.id)} checked={selectedExtractions[extraction.id]} />
                                    </TableCell>
                                    <TableCell>{extraction.name}</TableCell>
                                    <TableCell>{extraction.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Box sx={{ alignItems: 'center' }}>
                        <Typography component="span">AI Extractions (most accurate) </Typography>
                        <span style={{ fontSize: '0.8rem' }}> (Use AI prompts to extract data)</span>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Table>
                        <TableHead>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                        </TableHead>
                        <TableBody>
                            {aiExtraction.map((extraction) => (
                                <TableRow key={extraction.id} sx={{ cursor: 'pointer' }}>
                                    <TableCell>
                                        <Checkbox onChange={() => toggleExtraction(extraction.id)} checked={selectedExtractions[extraction.id]} />
                                    </TableCell>
                                    <TableCell>{extraction.name}</TableCell>
                                    <TableCell>{extraction.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>

        </Box >
    );
};

export default ExtractionInput;
