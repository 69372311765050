export const URLS = {
    profile: () => '/user/account',
    addTeam: () => '/teams/add',

    staffUserList: () => '/staff/manage-users',
    staffUserListWithEmailQuery: (userEmail: string) => `/staff/manage-users?email=${userEmail}`,
    staffTeamList: () => '/staff/manage-teams',
    staffObstractProfiles: () => '/staff/extraction-profiles',
    staffObstractProfileDetail: (id: string) => `/staff/extraction-profiles/${id}`,
    staffObstractFeeds: () => '/staff/manage-feeds',
    staffObstractFeed: (feedId: string) => `/staff/feeds/${feedId}`,
    staffObstractFeedEdit: (feedId: string) => `/staff/feeds/${feedId}/edit`,
    staffObstractPost: (feedId: string, postId: string) => `/staff/feeds/${feedId}/posts/${postId}`,
    staffObstractJobs: (feedId: string) => `/staff/feeds/${feedId}/jobs`,
    staffObstractJob: (feedId: string, jobId: string) => `/staff/feeds/${feedId}/jobs/${jobId}`,
    staffObservationSearch: (type: string, value: string) => `/staff/intelligence/search?type=${type}&value=${value}`,
    staffObservablePosts: (objectId: string, type: string, value: string) => `/staff/intelligence/${objectId}??type=${type}&value=${value}`,
    staffAddSkeletonFeed: () => `/staff/feeds/add-skeleton-feed`,
    staffLatestPosts: () => `/staff/posts/`,

    teamManagement: (team_id) => `/teams/${team_id}`,
    teamFeeds: (teamId) => `/feeds?team_id=${teamId}`,
    teamFeed: (teamId, feedId) => `/feeds/${feedId}?team_id=${teamId}`,
    teamFeedPost: (teamId, feedId, postId) => `/feeds/${feedId}/posts/${postId}?team_id=${teamId}`,
    teamObservationSearch: (teamId, type: string, value: string) => `/intelligence/search?type=${type}&value=${value}&team_id=${teamId}`,
    teamObservationSearchWithoutParams: (teamId: string) => `/intelligence/search?team_id=${teamId}`,
    teamObservablePosts: (objectId: string, type: string, value: string) => `/intelligence/${objectId}?type=${type}&value=${value}`,
    teamLatestPosts: (teamId: string) => `/posts?teamId=${teamId}`,
}
