import { ObstractsObject } from "./obstract"

export const getDateString = (dateString: string | undefined) => {
  if (!dateString) return '-'
  return JSON.stringify(new Date(dateString)).replaceAll('"', '').replaceAll('.000Z', ' UTC')
}

export const getScoValue = (sco: ObstractsObject) => {
  if (sco.type === 'file') {
    if (sco.name) return sco.name
    if (sco.hashes) {
      return Object.values(sco.hashes)[0]
    }
  }
  return sco.value || sco.number || sco.iban_number || sco.name || sco.key || sco.path || sco.string || sco.address
}

export const cleanData = (data: object) => {
  const result: Object = {}
  Object.keys(data).forEach(key => {
    if (data[key] !== "") {
      result[key] = data[key]
    }
  })
  return result
}

export const updateURLWithParams = (params) => {
  const queryString = new URLSearchParams(params).toString();
  const newURL = `${window.location.pathname}?${queryString}`;
  window.history.replaceState(null, "", newURL);
};
