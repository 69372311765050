import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
    adminFetchFeedJob,
    Feed,
    fetchFeedHistoryJob,
    fetchFeedJob,
    fetchObstractFeed,
    HistoryJob,
    IJob,
} from '../../services/obstract.ts';
import { URLS } from '../../services/urls.ts';

const JobDetailsPage: React.FC = () => {
    const { teamId, feedId, jobId } = useParams<{ teamId: string; feedId: string, jobId: string }>();
    const [job, setJob] = useState<IJob>();
    const [historyJob, setHistoryJob] = useState<HistoryJob>()
    const [feed, setFeed] = useState<Feed>()
    const [loading, setLoading] = useState<boolean>(true);

    const loadFeed = async () => {
        if (!feedId) return
        const res = await fetchObstractFeed(feedId)
        setFeed(res.data)
    }

    const loadJob = async () => {
        if (!feedId || !jobId) return
        setLoading(true);
        try {
            const response = teamId ? await fetchFeedJob(teamId, feedId, jobId) : await adminFetchFeedJob(feedId, jobId);
            setJob(response.data);
            const historyRes = await fetchFeedHistoryJob(teamId || '', feedId, jobId)
            setHistoryJob(historyRes.data)
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        document.title = 'Job Detail | Obstracts Web'
    }, [])

    useEffect(() => {
        loadFeed()
        loadJob();
    }, [teamId, feedId, jobId]);

    return (
        <Box p={4}>
            <Link
                to={URLS.staffObstractFeed(feedId || '')}
                style={{ textDecoration: "none" }}
            >
                <Typography variant="h5">
                    {feed?.obstract_feed_metadata["title"]}
                </Typography>
            </Link>
            <Typography variant="h4">Job ID: {job?.id}</Typography>
            <Typography sx={{ marginTop: "1rem" }}>
                <p>All the Jobs run for this feed</p>
            </Typography>

            <Box mt={4}>
                <Typography variant="h5">Extraction Job</Typography>
                <SyntaxHighlighter language="json" style={solarizedlight}>
                    {JSON.stringify(job, null, 2)}
                </SyntaxHighlighter>
            </Box>

            <Box mt={4}>
                <Typography variant="h5">history4feed Job</Typography>
                <SyntaxHighlighter language="json" style={solarizedlight}>
                    {JSON.stringify(historyJob, null, 2)}
                </SyntaxHighlighter>
            </Box>
        </Box>
    );
};

export default JobDetailsPage;