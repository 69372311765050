export const observable_types = [
  {
      "label": "artifact (SCO)",
      "value": "artifact",
      "field_name": "Payload Bin"
  },
  {
      "label": "attack-pattern(SDO)",
      "value": "attack-pattern",
      "field_name": "Value"
  },
  {
      "label": "autonomous-system (SCO)",
      "value": "autonomous-system",
      "field_name": "Number"
  },
  {
      "label": "bank-account (SCO)",
      "value": "bank-account",
      "field_name": "IBAN Number"
  },
  {
      "label": "bank-card (SCO)",
      "value": "bank-card",
      "field_name": "Number"
  },
  {
      "label": "campaign(SDO)",
      "value": "campaign",
      "field_name": "Value"
  },
  {
      "label": "course-of-action(SDO)",
      "value": "course-of-action",
      "field_name": "Value"
  },
  {
      "label": "cryptocurrency-transaction (SCO)",
      "value": "cryptocurrency-transaction",
      "field_name": "Hash"
  },
  {
      "label": "cryptocurrency-wallet (SCO)",
      "value": "cryptocurrency-wallet",
      "field_name": "Hash"
  },
  {
      "label": "directory (SCO)",
      "value": "directory",
      "field_name": "Path"
  },
  {
      "label": "domain-name (SCO)",
      "value": "domain-name",
      "field_name": "Value"
  },
  {
      "label": "email-addr (SCO)",
      "value": "email-addr",
      "field_name": "Value"
  },
  {
      "label": "email-message (SCO)",
      "value": "email-message",
      "field_name": "Body"
  },
  {
      "label": "file (SCO)",
      "value": "file",
      "field_name": "Name"
  },
  {
      "label": "ipv4-addr (SCO)",
      "value": "ipv4-addr",
      "field_name": "Value"
  },
  {
      "label": "ipv6-addr (SCO)",
      "value": "ipv6-addr",
      "field_name": "Value"
  },
  {
      "label": "identity (SDO)",
      "value": "identity",
      "field_name": "Value"
  },
  {
      "label": "infrastructure (SDO)",
      "value": "infrastructure",
      "field_name": "Value"
  },
  {
      "label": "intrusion-set (SDO)",
      "value": "intrusion-set",
      "field_name": "Value"
  },
  {
      "label": "location (SDO)",
      "value": "location",
      "field_name": "Value"
  },
  {
      "label": "mac-addr (SCO)",
      "value": "mac-addr",
      "field_name": "Value"
  },
  {
      "label": "malware (SDO)",
      "value": "malware",
      "field_name": "Value"
  },
  {
      "label": "mutex (SCO)",
      "value": "mutex",
      "field_name": "Value"
  },
  {
      "label": "network-traffic (SCO)",
      "value": "network-traffic",
      "field_name": "Protocols"
  },
  {
      "label": "note (SDO)",
      "value": "note",
      "field_name": "Value"
  },
  {
      "label": "phone-number (SCO)",
      "value": "phone-number",
      "field_name": "Number"
  },
  {
      "label": "process (SCO)",
      "value": "process",
      "field_name": "PID"
  },
  {
      "label": "report (SDO)",
      "value": "report",
      "field_name": "Value"
  },
  {
      "label": "software (SCO)",
      "value": "software",
      "field_name": "Name"
  },
  {
      "label": "threat-actor (SDO)",
      "value": "threat-actor",
      "field_name": "Value"
  },
  {
      "label": "tool (SDO)",
      "value": "tool",
      "field_name": "Value"
  },
  {
      "label": "url (SCO)",
      "value": "url",
      "field_name": "Value"
  },
  {
      "label": "user-account (SCO)",
      "value": "user-account",
      "field_name": "Display Name"
  },
  {
      "label": "user-agent (SCO)",
      "value": "user-agent",
      "field_name": "String"
  },
  {
      "label": "vulnerability (SDO)",
      "value": "vulnerability",
      "field_name": "Value"
  },
  {
      "label": "weakness (SDO)",
      "value": "weakness",
      "field_name": "Value"
  },
  {
      "label": "windows-registry-key (SCO)",
      "value": "windows-registry-key",
      "field_name": "Key"
  },
  {
      "label": "x509-certificate (SCO)",
      "value": "x509-certificate",
      "field_name": "Subject"
  }
];