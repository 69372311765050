import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Tooltip,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  adminFetchPostJobs,
  Feed,
  fetchObstractFeed,
  fetchObstractPost,
  fetchPostMarkdown,
  fetchPostObjects,
  fetchTeamPostObjects,
  IJob,
  ObstractsObject,
  Post,
} from "../../../services/obstract.ts";

import "./styles.css";
import { URLS } from "../../../services/urls.ts";
import { TeamContext } from "../../../contexts/team-context.tsx";
import { TeamRouteContext } from "../../team-layout.tsx/index.tsx";
import { getDateString, getScoValue } from "../../../services/utils.ts";
import ReindexingDialog from "./reindex.tsx";
import DeleteDialog from "./delete-dialog.tsx";
import CheckForUpdatesDialog from "./check-for-update-dialog.tsx";
import DeleteFromObjectDialog from "./confirm-delete-from-object.tsx";
import CheckForUpdateCompleteDialog from "./check-for-updates-dialog-complete.tsx";

const REACT_APP_VULMATCH_WEB_URL = process.env.REACT_APP_VULMATCH_WEB_URL;
const REACT_APP_CTIBUTLER_WEB_URL = process.env.REACT_APP_CTIBUTLER_WEB_URL;
const REACT_APP_PUBLIC_API_BASE_URL = process.env.REACT_APP_PUBLIC_API_BASE_URL;
const REACT_APP_OBSTRACTS_API_SWAGGER_URL =
  process.env.REACT_APP_OBSTRACTS_API_SWAGGER_URL;
const REACT_APP_TAXII_SWAGGER_URL = process.env.REACT_APP_TAXII_SWAGGER_URL;
const PAGE_SIZE = 50;

const TTP_TYPES = new Set([
  "attack-pattern",
  "campaign",
  "course-of-action",
  "identity",
  "infrastructure",
  "intrusion-set",
  "location",
  "malware",
  "note",
  "report",
  "threat-actor",
  "tool",
]);

const SCO_TYPES = new Set([
  "domain-name",
  "autonomous-system",
  "bank-account",
  "bank-card",
  "cryptocurrency-transaction",
  "cryptocurrency-wallet",
  "directory",
  "email-addr",
  "file",
  "ipv4-addr",
  "ipv6-addr",
  "mac-addr",
  "network-traffic",
  "phone-number",
  "url",
  "user-agent",
  "windows-registry-key",
]);

const CURL_OBJECTS_COMMAND = `
curl -X 'GET' \\
    '${REACT_APP_PUBLIC_API_BASE_URL}/v1/feeds/feedId/posts/postId/objects/' \\
    -H 'accept: application/json' \\
    -H 'API-KEY: XXXX'
`;

const CTIBUTLER_ATTACK_TYPE_CONVERSION_DICT = {
  "enterprise-attack": "attack-enterprise",
  "mobile-attack": "attack-mobile",
  "ics-attack": "attack-ics",
};
const PostDetailsPage: React.FC = () => {
  const { feedId, postId } = useParams<{ feedId: string; postId: string }>();
  const { teamId } = useContext(TeamRouteContext);
  const [objects, setObjects] = useState<ObstractsObject[]>([]);
  const [post, setPost] = useState<Post>();
  const [jobs, setJobs] = useState<IJob[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [jobUrl, setJobUrl] = useState("");
  const [feed, setFeed] = useState<Feed>();
  const [feedUrl, setFeedUrl] = useState("");
  const { activeTeam } = useContext(TeamContext);
  const [reportId, setReportId] = useState("");
  const [showReindexDialog, setShowReindexDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [reIndexJobId, setReIndexJobId] = useState("");
  const [showCheckForUpdateModal, setShowCheckForUpdateModal] = useState(false);
  const [showCheckForUpdateCompleteModal, setShowCheckForUpdateCompleteModal] =
    useState(false);
  const [markdown, setMarkdown] = useState("");
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [selectedObject, setSelectedObject] = useState<ObstractsObject>();
  const [
    showConfirmDeleteFromObjectDialog,
    setShowConfirmDeleteFromObjectDialog,
  ] = useState(false);
  const navigate = useNavigate();

  const stixRef = useRef(null);

  const loadPost = async () => {
    if (!postId || !feedId) return;
    const response = await fetchObstractPost(feedId, postId);
    setPost(response.data);
  };

  const loadJobs = async () => {
    if (!postId || !feedId) return;
    if (!teamId) {
      const response = await adminFetchPostJobs(feedId, postId);
      setJobs(response.data.jobs);
    }
  };

  const loadFeed = async () => {
    if (!feedId) return;
    if (teamId) {
      setFeedUrl(URLS.teamFeed(teamId, feedId));
    } else {
      setFeedUrl(URLS.staffObstractFeed(feedId));
    }
    const res = await fetchObstractFeed(feedId);

    setFeed(res.data);
  };

  useEffect(() => {
    loadPost();
    loadFeed();
    loadJobs();
    loadObjects();
    loadMarkdown();
  }, [feedId, postId]);

  const fetchAllObjectsApi = async (
    feedId: string,
    postId: string,
    page: number
  ) => {
    if (teamId) {
      return fetchTeamPostObjects(teamId, feedId, postId, page);
    } else {
      return fetchPostObjects(feedId, postId, page);
    }
  };
  const fetchAllObjects = async (
    feedId: string,
    postId: string,
    page: number
  ) => {
    const data = await fetchAllObjectsApi(feedId, postId, page);
    const total_results_count = data.total_results_count;
    setObjects((objects) => [...objects, ...data.objects]);
    if (total_results_count / data.page_size > data.page_number) {
      await fetchAllObjects(feedId, postId, page + 1);
    }
    setTotalPages(Math.ceil(data.total_results_count / PAGE_SIZE));
  };

  const loadObjects = async () => {
    if (!postId || !feedId) return;
    setLoading(true);
    try {
      await fetchAllObjects(feedId, postId, 1);
    } catch (error) {
      console.error("Failed to fetch jobs:", error);
      // Handle error (e.g., show a notification)
    } finally {
      setLoading(false);
    }
  };

  const getStixObject = () => {
    const reportId = objects.find((object) => object.type === "report")?.id;
    setReportId(reportId || "");
    return {
      type: "bundle",
      id: `bundle--${reportId}`,
      spec_version: "2.1",
      objects,
    };
  };

  const loadStixData = () => {
    if (loading) return;
    if (!scriptLoaded) return;
    if (objects.length === 0) return;
    const graph = window.stixview.init(
      stixRef.current,
      null,
      () => {
        console.info("Graph loaded");
      },
      {}, // no additional data properties
      {
        hideFooter: false,
        showSidebar: true,
        maxZoom: 50,
        onClickNode: function (node) {},
      }
    );
    const data = getStixObject();
    graph.loadData(data);
  };

  useEffect(loadStixData, [loading, scriptLoaded]);

  const getJobUrl = () => {
    const jobId = jobs.length > 0 ? jobs[0].id : null;
    if (!jobId || !feedId) return "";
    return URLS.staffObstractJob(feedId, jobId);
  };

  useEffect(() => {
    const url = getJobUrl();
    setJobUrl(url);
  }, [jobs, feedId]);

  const getObservationSearchUrl = (object: ObstractsObject) => {
    const value = object.value ?? object.name;
    if (teamId)
      return URLS.teamObservablePosts(
        object.id,
        object.type,
        getScoValue(object)
      );
    return URLS.staffObservablePosts(
      object.id,
      object.type,
      getScoValue(object)
    );
  };

  useEffect(() => {
    document.title = `${post?.title} | Obstracts Web`;
  }, [post]);

  const downloadStixObject = async () => {
    const data = getStixObject();
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: "application/json",
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const reportUUID = reportId.split("--")[1];
    link.download = `bundle--${reportUUID}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  const checkForUpdate = async () => {
    if (!feedId || !postId) return;
    setShowReindexDialog(true);
  };

  const loadMarkdown = async () => {
    if (!feedId || !postId) return;
    const response = await fetchPostMarkdown(feedId, postId);
    setMarkdown(response.data);
  };

  const downloadMarkdown = async () => {
    const blob = new Blob([markdown], { type: "text/md" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${reportId}.md`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  const onConfirmReIndex = (jobId: string) => {
    setReIndexJobId(jobId);
    setShowCheckForUpdateCompleteModal(true);
    setShowCheckForUpdateModal(false);
  };

  const isMitreAttack = (object: ObstractsObject) => {
    const keywords = ["enterprise-attack", "ics-attack", "mobile-attack"];
    if (object.x_mitre_domains) {
      return (
        object.x_mitre_domains.findIndex((item) => keywords.includes(item)) > -1
      );
    }
    return false;
  };
  const getCtiButlerURL = (object: ObstractsObject) => {
    const keywords = ["enterprise-attack", "ics-attack", "mobile-attack"];
    let objectType: string | undefined = "";
    if (object.x_mitre_domains) {
      objectType = object.x_mitre_domains.find((item) =>
        keywords.includes(item)
      );
      if (!objectType) return "#";
      const knowledgebaseUrlPath =
        CTIBUTLER_ATTACK_TYPE_CONVERSION_DICT[objectType];
      return `${REACT_APP_CTIBUTLER_WEB_URL}/knowledgebases/${knowledgebaseUrlPath}/${object.id}`;
    }
  };

  const getCapecs = () => {
    return objects.filter((object) => object.x_capec_version);
  };

  const getObjectReferenceID = (object: ObstractsObject) => {
    if (!object.external_references) return;
    return object.external_references[0].external_id;
  };
  const getMitreAttacks = () => {
    return objects.filter((object) => isMitreAttack(object));
  };

  const isIndicator = (object: ObstractsObject) => {
    return object.type === "indicator";
  };

  const isSDOType = (object: ObstractsObject) => {
    if (postId && object.id?.includes(postId)) return false;
    if (object.id === "identity--a1f2e3ed-6241-5f05-ac2e-3394213b8e08")
      return false;
    return (
      TTP_TYPES.has(object.type) &&
      !isMitreAttack(object) &&
      !object.x_capec_version
    );
  };

  const removeObject = async (object: ObstractsObject) => {
    setSelectedObject(object);
    setShowConfirmDeleteFromObjectDialog(true);
  };
  const onDeleteFromSelectedObject = () => {
    setObjects((objects) =>
      objects.filter((object) => object.id != selectedObject?.id)
    );
    setShowConfirmDeleteFromObjectDialog(false);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/stixview.bundle.js";
    script.async = true;
    script.onload = () => {
      setScriptLoaded(true);
    };

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const getTTps = () => {
    return objects.filter((object) => isSDOType(object));
  };

  const getCVEs = () => {
    return objects.filter((object) => object.type === "vulnerability");
  };

  const getCWEs = () => {
    return objects.filter((object) => object.type === "weakness");
  };

  const getObservables = () => {
    return objects.filter((object) => SCO_TYPES.has(object.type));
  };

  const getDetections = () => objects.filter((object) => isIndicator(object));
  const getProfileId = () => post?.profile_id || feed?.profile_id;
  return (
    <Box p={4}>
      <Box>
        <Box sx={{ display: "flex" }}>
          <Link to={feedUrl} style={{ textDecoration: "none" }}>
            <Typography variant="h5">
              {feed?.obstract_feed_metadata.title}
            </Typography>
          </Link>
          {!teamId && (
            <>
              <Button
                variant="contained"
                sx={{ marginLeft: "2rem" }}
                onClick={() => setShowCheckForUpdateModal(true)}
              >
                Check for updates
              </Button>
              <Button
                color="error"
                variant="contained"
                sx={{ marginLeft: "2rem" }}
                onClick={() => setShowDeleteDialog(true)}
              >
                Delete Post
              </Button>
            </>
          )}
        </Box>
        <Typography variant="h4" gutterBottom>
          {post?.title}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", marginBottom: "1rem" }}>
        <Typography variant="h5"> Summary</Typography>
      </Box>
      <p>{post ? post.summary || "No summary found" : "Loading..."}</p>
      <Table sx={{ marginTop: "1rem", marginBottom: "2rem" }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Feed Setting</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Post Date</TableCell>
            <TableCell>{getDateString(post?.pubdate)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Post Author</TableCell>
            <TableCell>{post?.author}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Post Label</TableCell>
            <TableCell>{post?.categories?.join(", ")}</TableCell>
          </TableRow>
          {!teamId && (
            <>
              <TableRow>
                <TableCell>Profile ID</TableCell>
                <TableCell>
                  <Link
                    to={URLS.staffObstractProfileDetail(getProfileId() || "")}
                  >
                    {getProfileId()}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Is Full Text</TableCell>
                <TableCell>{post?.is_full_text ? "True" : "False"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Added Manually</TableCell>
                <TableCell>{post?.added_manually ? "True" : "False"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Report ID</TableCell>
                <TableCell>{reportId}</TableCell>
              </TableRow>
              {!teamId && (
                <>
                  <TableRow>
                    <TableCell>Date Added</TableCell>
                    <TableCell>{getDateString(post?.datetime_added)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Date Updated</TableCell>
                    <TableCell>
                      {getDateString(post?.datetime_updated)}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", marginBottom: "1rem" }}>
        <Typography variant="h5"> Post Content</Typography>
      </Box>
      <div
        style={{
          border: "1px solid #000000cc",
          padding: "3rem",
          borderRadius: "5px",
          maxWidth: "80vw",
        }}
      >
        <SyntaxHighlighter
          showLineNumbers
          useInlineStyles
          wrapLines
          wrapLongLines
          language="text"
        >
          {markdown}
        </SyntaxHighlighter>
      </div>

      <Box sx={{ display: "flex", marginTop: "1rem", justifyContent: "right" }}>
        <Link to={post?.link || ''} target="_blank">
          <Button
            variant="contained"
            sx={{ textTransform: "uppercase", marginLeft: "1rem" }}
          >
            View on blog
          </Button>
        </Link>
        <Box sx={{ marginLeft: "1rem" }}>
          <Button onClick={downloadMarkdown} variant="contained">
            Download Markdown
          </Button>
        </Box>
      </Box>

      {loading ? (
        <CircularProgress style={{ display: "block", margin: "20px auto" }} />
      ) : (
        <>
          <TableContainer sx={{ marginTop: "3rem" }}>
            <Typography variant="h5">Observables</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20%" }}>Type</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell sx={{ width: "38%" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getObservables().length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No extractions found</TableCell>
                  </TableRow>
                )}
                {getObservables().map((object, index) => (
                  <TableRow className="ioc-row" key={index}>
                    <TableCell sx={{ width: "20%" }}>{object.type}</TableCell>
                    <TableCell>{getScoValue(object)}</TableCell>
                    <TableCell sx={{ width: "38%" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ textTransform: "uppercase" }}
                        onClick={() => {
                          navigate(getObservationSearchUrl(object));
                        }}
                      >
                        View all posts
                      </Button>
                      {!teamId && (
                        <Button
                          color="error"
                          variant="contained"
                          sx={{
                            textTransform: "uppercase",
                            marginLeft: "2rem",
                          }}
                          onClick={() => {
                            removeObject(object);
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer sx={{ marginTop: "3rem" }}>
            <Typography variant="h5">TTPs</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20%" }}>Type</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell sx={{ width: "38%" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getTTps().length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No extractions found</TableCell>
                  </TableRow>
                )}
                {getTTps().map((object, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ width: "20%" }}>{object.type}</TableCell>
                    <TableCell>{object.name ?? object.value}</TableCell>
                    <TableCell sx={{ width: "38%" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ textTransform: "uppercase" }}
                        onClick={() => {
                          navigate(getObservationSearchUrl(object));
                        }}
                      >
                        View all posts
                      </Button>
                      {object.type === "vulnerability" && (
                        <a
                          href={`${REACT_APP_VULMATCH_WEB_URL}/vulnerabilities/${object.name}`}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{
                              textTransform: "uppercase",
                              margin: "0.5rem",
                            }}
                          >
                            View in vulmatch
                          </Button>
                        </a>
                      )}
                      {object.type === "weakness" && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${REACT_APP_CTIBUTLER_WEB_URL}/knowledgebases/cwe/${object.id}`}
                        >
                          <Button
                            sx={{
                              margin: "0.5rem",
                            }}
                            variant="contained"
                          >
                            View in CTI Butler
                          </Button>
                        </a>
                      )}
                      {!teamId && (
                        <Button
                          color="error"
                          variant="contained"
                          sx={{
                            textTransform: "uppercase",
                            marginLeft: "2rem",
                          }}
                          onClick={() => {
                            removeObject(object);
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer sx={{ marginTop: "3rem" }}>
            <Typography variant="h5">CVEs</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "10%" }}>CVE ID</TableCell>
                  <TableCell>Cve Description</TableCell>
                  <TableCell sx={{ width: "30%" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getCVEs().length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No extractions found</TableCell>
                  </TableRow>
                )}
                {getCVEs().map((object, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ width: "20%" }}>{object.name}</TableCell>
                    <TableCell>{object.description}</TableCell>
                    <TableCell sx={{ width: "38%" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ textTransform: "uppercase" }}
                        onClick={() => {
                          navigate(getObservationSearchUrl(object));
                        }}
                      >
                        View all posts
                      </Button>
                      {object.type === "vulnerability" && (
                        <a
                          href={`${REACT_APP_VULMATCH_WEB_URL}/vulnerabilities/${object.name}`}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{
                              textTransform: "uppercase",
                              margin: "0.5rem",
                            }}
                          >
                            View in vulmatch
                          </Button>
                        </a>
                      )}
                      {!teamId && (
                        <Button
                          color="error"
                          variant="contained"
                          sx={{
                            textTransform: "uppercase",
                            marginLeft: "2rem",
                          }}
                          onClick={() => {
                            removeObject(object);
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer sx={{ marginTop: "3rem" }}>
            <Typography variant="h5">ATT&CK</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20%" }}>ATT&CK ID</TableCell>
                  <TableCell>ATT&CK Name</TableCell>
                  <TableCell sx={{ width: "38%" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getMitreAttacks().length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No extractions found</TableCell>
                  </TableRow>
                )}
                {objects
                  .filter((object) => isMitreAttack(object))
                  .map((object, index) => (
                    <TableRow className="ioc-row" key={index}>
                      <TableCell sx={{ width: "20%" }}>
                        {getObjectReferenceID(object)}
                      </TableCell>
                      <TableCell>{object.name}</TableCell>
                      <TableCell sx={{ width: "38%" }}>
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ textTransform: "uppercase" }}
                          onClick={() => {
                            navigate(getObservationSearchUrl(object));
                          }}
                        >
                          View all posts
                        </Button>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={getCtiButlerURL(object)}
                        >
                          <Button
                            sx={{
                              margin: "0.5rem",
                            }}
                            variant="contained"
                          >
                            View in CTI Butler
                          </Button>
                        </a>
                        {!teamId && (
                          <Button
                            color="error"
                            variant="contained"
                            sx={{
                              textTransform: "uppercase",
                              marginLeft: "2rem",
                            }}
                            onClick={() => {
                              removeObject(object);
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer sx={{ marginTop: "3rem" }}>
            <Typography variant="h5">CWEs</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "10%" }}>CWE ID</TableCell>
                  <TableCell>CWE Name</TableCell>
                  <TableCell sx={{ width: "30%" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getCWEs().length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No extractions found</TableCell>
                  </TableRow>
                )}
                {getCWEs().map((object, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ width: "20%" }}>
                      {getObjectReferenceID(object)}
                    </TableCell>
                    <TableCell>{object.name}</TableCell>
                    <TableCell sx={{ width: "38%" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ textTransform: "uppercase" }}
                        onClick={() => {
                          navigate(getObservationSearchUrl(object));
                        }}
                      >
                        View all posts
                      </Button>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${REACT_APP_CTIBUTLER_WEB_URL}/knowledgebases/cwe/${object.id}`}
                      >
                        <Button
                          sx={{
                            margin: "0.5rem",
                          }}
                          variant="contained"
                        >
                          View in CTI Butler
                        </Button>
                      </a>
                      {!teamId && (
                        <Button
                          color="error"
                          variant="contained"
                          sx={{
                            textTransform: "uppercase",
                            marginLeft: "2rem",
                          }}
                          onClick={() => {
                            removeObject(object);
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer sx={{ marginTop: "3rem" }}>
            <Typography variant="h5">CAPEC</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "10%" }}>CAPEC ID</TableCell>
                  <TableCell>CAPEC Name</TableCell>
                  <TableCell sx={{ width: "30%" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getCWEs().length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No extractions found</TableCell>
                  </TableRow>
                )}
                {getCapecs().map((object, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ width: "20%" }}>
                      {getObjectReferenceID(object)}
                    </TableCell>
                    <TableCell>{object.name}</TableCell>
                    <TableCell sx={{ width: "38%" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ textTransform: "uppercase" }}
                        onClick={() => {
                          navigate(getObservationSearchUrl(object));
                        }}
                      >
                        View all posts
                      </Button>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${REACT_APP_CTIBUTLER_WEB_URL}/knowledgebases/cwe/${object.id}`}
                      >
                        <Button
                          sx={{
                            margin: "0.5rem",
                          }}
                          variant="contained"
                        >
                          View in CTI Butler
                        </Button>
                      </a>
                      {!teamId && (
                        <Button
                          color="error"
                          variant="contained"
                          sx={{
                            textTransform: "uppercase",
                            marginLeft: "2rem",
                          }}
                          onClick={() => {
                            removeObject(object);
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <Box sx={{ marginTop: "5rem" }}>
        <Typography variant="h5">Extraction Graph</Typography>
        <div ref={stixRef}></div>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {!teamId || activeTeam?.allowed_data_download ? (
            <Button onClick={downloadStixObject} variant="contained">
              Download
            </Button>
          ) : (
            <Tooltip title="Your team plan must be upgraded to allow data downloads to download the bundle">
              <div>
                <Button variant="contained" disabled={true}>
                  Download
                </Button>
              </div>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box sx={{ marginTop: "5rem" }}>
        <Typography variant="h5">API Request</Typography>
        <Typography>
          <p>
            You can use the REST API to download the STIX bundle for this
            object.
          </p>
        </Typography>
        <Box>
          <SyntaxHighlighter language="bash" style={solarizedlight}>
            {CURL_OBJECTS_COMMAND.replace("feedId", feedId || "").replace(
              "postId",
              postId || ""
            )}
          </SyntaxHighlighter>
          <Box>
            <Typography>
              <p>
                Replace <code className="confirm-text">XXXX</code> with your API
                key. You can generate this on your{" "}
                <Link target="_blank" to={URLS.profile()}>
                  {" "}
                  account settings page
                </Link>{" "}
                (only if your teams plan supports API access).
              </p>
            </Typography>
            <Typography>
              <p>
                You can do lots more with the APIs to integrate this data with
                your other tooling. Check the{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={REACT_APP_OBSTRACTS_API_SWAGGER_URL}
                >
                  REST API docs
                </a>{" "}
                or{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={REACT_APP_TAXII_SWAGGER_URL}
                >
                  TAXII API docs
                </a>{" "}
                for more information.
              </p>
            </Typography>
          </Box>
        </Box>
      </Box>

      {feedId && (
        <ReindexingDialog
          feedId={feedId}
          onClose={() => setShowReindexDialog(false)}
          open={showReindexDialog}
          jobId={reIndexJobId}
        ></ReindexingDialog>
      )}
      {feedId && post && (
        <CheckForUpdateCompleteDialog
          feedId={feedId}
          post={post}
          onClose={() => setShowCheckForUpdateCompleteModal(false)}
          open={showCheckForUpdateCompleteModal}
          jobId={reIndexJobId}
        ></CheckForUpdateCompleteDialog>
      )}
      {post && (
        <DeleteDialog
          onClose={() => setShowDeleteDialog(false)}
          open={showDeleteDialog}
          post={post}
          feedId={feedId}
        ></DeleteDialog>
      )}
      {post && feed && (
        <CheckForUpdatesDialog
          onConfirmReIndex={onConfirmReIndex}
          onClose={() => setShowCheckForUpdateModal(false)}
          open={showCheckForUpdateModal}
          post={post}
          feed={feed}
        ></CheckForUpdatesDialog>
      )}
      {selectedObject && post && (
        <DeleteFromObjectDialog
          open={showConfirmDeleteFromObjectDialog}
          onClose={() => setShowConfirmDeleteFromObjectDialog(false)}
          onDelete={() => onDeleteFromSelectedObject()}
          object={selectedObject}
          reportId={reportId}
          post={post}
        ></DeleteFromObjectDialog>
      )}
    </Box>
  );
};

export default PostDetailsPage;
