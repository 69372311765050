import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { deleteReportFromObject, ObstractsObject, Post } from '../../../services/obstract.ts';
import LoadingButton from '../../../components/loading_button/index.tsx';
import { getScoValue } from '../../../services/utils.ts';

interface DeleteFromObjectDialogProps {
  object: ObstractsObject;
  post: Post,
  reportId: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteFromObjectDialog: React.FC<DeleteFromObjectDialogProps> = ({ object, reportId, post, open, onClose, onDelete }) => {
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    setLoading(true)
    await deleteReportFromObject(object.id, reportId)
    onDelete()
    setLoading(false)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        Are you sure you want to delete the object
        <code className='confirm-text' style={{ margin: '0 0.3rem' }}>{getScoValue(object)}({object.id})</code>
        from the report
        <code className='confirm-text' style={{ margin: '0 0.3rem' }}>{post.title}({reportId})</code>?
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose} color="primary">
          Cancel
        </Button>
        <LoadingButton isLoading={loading} variant='contained' onClick={handleDelete} color="error">
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFromObjectDialog;